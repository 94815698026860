import React from "react";
import Flex from "app/components/Flex";
import { Waypoint } from "app/components/Waypoint";
import { useInstantSearch } from "react-instantsearch-hooks-web";
import { useGetPlaylistStudioClassHits } from "./hooks";
import { EmptyStateList } from "./EmptyStateList";
import { AlgoliaPlaylistClassData } from "../types";
import { ClassHit } from "./ClassHit";

interface Props {
  playlistId: string;
}

export function SearchResults({ playlistId }: Props) {
  const { status } = useInstantSearch();
  const { classHits, isLastPage, showMore } = useGetPlaylistStudioClassHits({
    playlistId,
  });
  const isLoading = status === "loading";

  return (
    <Flex flexDirection="column" flex="1" gap="16px" overflow="auto" pb="16px">
      {classHits.length > 0 ? (
        classHits.map((classHit: AlgoliaPlaylistClassData) => (
          <ClassHit
            key={classHit.id}
            classHit={classHit}
            playlistId={playlistId}
          />
        ))
      ) : (
        <EmptyStateList />
      )}
      {!isLoading && (
        <Waypoint fetchData={showMore || (() => {})} hasMore={!isLastPage} />
      )}
    </Flex>
  );
}
