import React from "react";
import Button from "app/components/Button";
import Flex from "app/components/Flex";
import styled from "styled-components";
import { ANALYTICS_MODULE } from "constants/analytics";
import ClassRow from "app/components/ClassRow";
import { IS_MOBILE } from "constants/index";
import { AlgoliaPlaylistClassData } from "../types";
import { AlgoliaClassRow } from "../PlaylistClassRow";
import {
  usePlaylistRemoveStudioClass,
  usePlaylistAddStudioClass,
} from "../hooks";

const DesktopAddButton = styled(Button)`
  padding: 12px 20px;
  border-radius: 5px;
`;

const MobileAddButton = styled(Button)`
  padding: 0;
  border-radius: 0;
  width: 110px;
`;

export function ClassHit({
  classHit,
  playlistId,
}: {
  classHit: AlgoliaPlaylistClassData;
  playlistId: string;
}) {
  const analyticsData = {
    class_id: classHit.id,
    module: ANALYTICS_MODULE.add_classes_modal,
  };
  const { playlistRemoveStudioClass } = usePlaylistRemoveStudioClass({
    playlistId,
    studioClassId: classHit.studioClassId,
    analyticsData,
  });
  const { playlistAddStudioClass } = usePlaylistAddStudioClass({
    playlistId,
    studioClassId: classHit.studioClassId,
    analyticsData,
  });

  const ClassRowComponent = IS_MOBILE ? MobileClassRow : DesktopClassRow;

  return (
    <Flex key={classHit.id} gap="16px">
      <ClassRowComponent
        classHit={classHit}
        removeClass={playlistRemoveStudioClass}
        addClass={playlistAddStudioClass}
      />
    </Flex>
  );
}

function MobileClassRow({
  classHit,
  removeClass,
  addClass,
}: {
  classHit: AlgoliaPlaylistClassData;
  removeClass(): void;
  addClass(): void;
}) {
  return (
    <Flex width="100%" justifyContent="space-between" maxWidth="768px">
      <ClassRow borderRadius="0" classData={classHit} />
      {classHit.isAddedToPlaylist ? (
        <MobileAddButton
          color="blue"
          bg="monochrome.1"
          hbg="monochrome.2"
          onClick={removeClass}
        >
          Added
        </MobileAddButton>
      ) : (
        <MobileAddButton onClick={addClass}>Add</MobileAddButton>
      )}
    </Flex>
  );
}

function DesktopClassRow({
  classHit,
  removeClass,
  addClass,
}: {
  classHit: AlgoliaPlaylistClassData;
  removeClass(): void;
  addClass(): void;
}) {
  return (
    <Flex width="100%" justifyContent="space-between" maxWidth="768px">
      <AlgoliaClassRow key={classHit.id} classHit={classHit} />
      <Flex alignItems="center">
        {classHit.isAddedToPlaylist ? (
          <DesktopAddButton
            color="blue"
            bg="monochrome.1"
            hbg="monochrome.2"
            borderRadius={0}
            onClick={removeClass}
          >
            Added
          </DesktopAddButton>
        ) : (
          <DesktopAddButton onClick={addClass} borderRadius={0}>
            Add
          </DesktopAddButton>
        )}
      </Flex>
    </Flex>
  );
}
