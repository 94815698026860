import { useGetPlaylistQuery } from "services/graphql";
import { useInfiniteHits } from "react-instantsearch-hooks-web";

export function useGetPlaylistStudioClassHits({
  playlistId,
  first = 10,
}: {
  playlistId: string;
  first?: number;
}) {
  const { hits, isLastPage, showMore } = useInfiniteHits();
  const { data } = useGetPlaylistQuery({
    variables: {
      playlistId,
      studioClassIds: hits.map((hit: any) => hit.studioClassId),
      pagination: {
        first,
      },
    },
  });

  const { playlist } = data || {};
  const classHits = hits.map(hit => {
    const studioClass = playlist?.hasStudioClasses.find(
      hasStudioClass => hasStudioClass.studioClassId === hit.studioClassId
    );
    return {
      ...hit,
      isAddedToPlaylist: studioClass?.hasStudioClass,
    };
  });

  return {
    classHits,
    isLastPage,
    showMore,
  };
}
